import React from 'react'
import { withRouter } from 'react-router-dom'

class CongressProgram extends React.Component {
    render() {
        return( 
            <div className="container">
                <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F"}}>ČETVRTAK 20.03.2025 godine:</p>
                        <p style={{fontStyle: 'italic'}}>(Dječija preventivna)</p>
                        <ul style={{}}>
                            <li>(9:00-9:30) <b>Registracija učesnika</b></li>
                            <li>(9:30-10:00) <i>Prof. dr Jelena Mandić:</i> <b>"Male oralno hirurške intervencije u dečjoj stomatološkoj praksi"</b></li>
                            <li>(10:00-10:30) <i>Prof. dr Mirjana Ivanović:</i> <b>„Moralnoinicijalna hipomineralizacija (MIH) - protokol zbrinjavanja"</b></li>
                            <li>(10:30-11:00) <i>Prof. dr Bojana Davidović:</i> <b>“Stomatološka zaštita trudnica – da li joj poklanjamo dovoljno pažnje?“</b> </li>
                            <li>
                                <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(11:00-11:30) Kafe pauza</p>
                            </li>
                            <li>(11:30-12:00) <i>Prof. dr Svjetlana Janković:</i> <b>"Ortodoncija u svakodnevnoj praksi dječijeg stomatologa"</b></li>
                            <li>(12:00-12:30) <i>Prof. dr Mirjana Đuričković:</i> <b>"Oralno zdravlje kod djece sportista: Prevencija kao ključ uspjeha"</b></li>
                            <li>(13:00-13:30) <b>Diskusija</b></li>
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(13:30-14:30) Ručak</p>
                        <p style={{fontStyle: 'italic'}}>(ENDODONCIJA)</p>
                        <ul style={{padding:'10px'}}>
                            <li>(14:30-15:00) <i>Prof. dr Branislav Karadžić:</i> <b>"Endodontsko hirurška terapija apeksnih parodontitisa danas"</b></li>
                            <li>(15:00-15:30) <i>Prof. dr Jelena Krunić:</i> <b>"Ishod endodontske terapije: da li sve zavisi od nas?"</b></li>
                            <li>(15:30-16:00) <i>Doc. dr Aleksandra Žuža:</i> <b>“Minimalno invazivna endodoncija : Paradigma savremene prakse“</b> </li>
                            <li>(16:00-16:45) <i>Dr Stevan Kopanja:</i> <b>"1 cement-1 šansa"</b></li>
                            <li>(16:45-15:15) <b>Diskusija</b></li>
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(19:00) Svečano otvaranje</p>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(19:30) Koktel dobrodošlice</p>
                    </div>
                </div>

                <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F"}}>PETAK 21.03.2025 godine:</p>
                        <p style={{fontStyle: 'italic'}}>(ORTODONCIJA)</p>
                        <ul style={{}}>
                            <li>(9:00-9:30) <b>Registracija učesnika</b></li>
                            <li>(9:30-10:00) <i>Prof. dr Ivana Šćepan:</i> <b>"Distalizacija umjesto ekstrakcije:primjeri iz svakodnevne prakse"</b></li>
                            <li>(10:00-10:30) <i>Prof. dr Nenad Nedeljković:</i> <b>„Savremeni pristup u ortodontskoj terapiji pomoću mini implantata”</b></li>
                            <li>(10:30-11:00) <i>Dr.sc. Antonija Jurela, dr.med.dent., spec ortodoncije:</i> <b>“Pretprotetska terapija alignerima“</b> </li>
                            <li>(11:00-11:30) <b>Kafe pauza</b></li>
                            <li>(11:30-12:00) <i>Prof. dr Neda Stefanović:</i> <b>"Širenje gornje vilice – zašto, kada i kako"</b></li>
                            <li>(12:00-12:30) <b>Diskusija</b></li>
                            {/* <li>(12:00-12:30) <i>prof. Zoran Lazić</i><b>"Imedijatna ugradnja, gde smo danas?"</b></li> */}
                            {/* <li>(12:30-13:30) <i> Prim. dr Dejan Lisjak i Sloboda Laslo:</i> <b>"10 ljutih gusara digitalne stomatologije"</b></li> */}
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(13:30-14:30) Ručak</p>
                        <p style={{fontStyle: 'italic'}}>(PARODONTOLOGIJA)</p>
                        <ul style={{padding:'10px'}}>
                            <li>(14:30-15:00) <i>Prof. dr Nataša Nikolić Jakoba:</i> <b>"Savremeni pristup u terapiji recesija gingive"</b></li>
                            <li>(15:00-15:30) <i>Prof. dr Mia Rakić:</i> <b>"Implantna površina u epicentru dijagnostičkih, terapijskih i preventivnih strategija periimplantitisa"</b></li>
                            <li>(15:30-16:00) <b>Kafe pauza</b></li>
                            <li>(16:00-16:30) <i>Dr spec. Uroš Tomić:</i> <b>“Oralno-medicinske komplikacije kao posledica stomatoloških intervencija“</b> </li>
                            <li>(16:30-17:00) <i>Prof. dr Saša Čakić :</i> <b>"Oralne ulceracije-diferencijalno dijagnostički značaj"</b></li>
                            <li>(17:00-17:30) <b>Diskusija</b></li>
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(20:30) Svečana večera</p>
                    </div>
                </div>

                <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F"}}>SUBOTA 22.03.2025 godine:</p>
                        <p style={{fontStyle: 'italic'}}>(HIRURGIJA)</p>
                        <ul style={{}}>
                            <li>(9:00-9:30) <b>Registracija učesnika</b></li>
                            <li>(09:30-10:00) <i>Dr Vladimir S. Todorović:</i> <b>„Anatomski parametri od značaja za uspešnost terapije zubnim implantima"</b></li>
                            <li>(10:00-10:30) <i>Dr Danijel Domić:</i> <b>"Imedijatna ugradnja implantata u estetskoj zoni: ključni faktori za dugoročnu uspešnost"</b></li>
                            <li>(10:30-11:00) <i>Dr Marko Magić:</i> <b>“Upotreba digitalnih alata postizanje optimalne trodimenzionalne pozicije implantata"</b> </li>
                            <li>(11:00-11:30) <b>Kafe pauza</b></li>
                            <li>(11:30-12:00) <i>Dr sc. med. Goran Stojković i dr Miloš Milošević:</i> <b>"Rehabilitacija ekstemno atrofične maksile  primenom zigomatičnih implantata"</b></li>
                            <li>(12:00-12:30) <i>Prof. dr Miroslav Andrić: </i><b>"Lečenje dentogenih infekcija u oralno-hirurškoj praksi"</b></li>
                            <li>(12:30-13:00) <i>Prof. dr Milan Jurišić: </i><b>"Augmentacione procedure uz primenu ksenogenih koštanih zamenika I kolagenih membrana"</b></li>
                            <li>(13:00-13:15) <b>Diskusija</b></li>
                        </ul>
                        <p style={{fontWeight:'bold', fontStyle: 'italic'}}>(13:15-14:00) Ručak</p>
                        <p style={{fontStyle: 'italic'}}>(PROTETIKA)</p>
                        <ul style={{padding:'10px'}}>
                            <li>(14:00-14:45) <i>Dr Verena Nizić:</i> <b>"Odabir i analiza slučajeva za terapiju keramičkim ljuskama"</b></li>
                            <li>(14:45-15:45) <i> Prim. dr Dejan Lisjak i Sloboda Laslo:</i> <b>"10 ljutih gusara digitalne stomatologije"</b></li>
                            <li>(15:45-16:15) <i>Andrej Lazić:</i> <b>"ZrO2-detalji koji cine razliku"</b></li>
                            <li>(16:15-16:45) <i>Prof. dr Milena Kostić:</i> <b>“Budućnost je počela - kako do idealnog odnosa krunice i predela demarkacije preparacije“</b> </li>
                            <li>(16:45-17:15) <b>Kafe pauza</b></li>
                            <li>(17:15-17:45) <i>Dr Marko Milićević:</i> <b>"Cerek protokoli 21. vijeka"</b></li>
                            <li>(18:15-18:45) <i>Prof. dr Adrijan Kasaj: </i><b>"Treatment of gingival recession: When, why and how?"</b></li>
                            <li>(18:45-18:15) <b>Diskusija</b></li>
                            <p style={{fontStyle: 'italic', paddingTop:"10px"}}>(ITI Study Club Montenegro sastanak)</p>
                            <li>(18:15-18:30) <i>Dr Milan Vučetić, prof.dr Miroslav Andrić: </i><b>"Dobrodošli u ITI Study Club Montenegro"</b></li>
                            <li>(18:30-19:00) <i>Dr Tanja Živanović (Klinika za Parodontologiju i Oralnu medicinu, Stomatološki fakultet, Univerzitet u Beogradu, Srbija): </i><b>"Moja ITI stipendija - Sinergija kliničkog i istraživačkog rada tokom jednogodišnjeg boravka u Londonu"</b></li>
                            <li>(19:00-20:00) <i>Dr Avish Jay Jagathpal (Department for Prosthodontics, Univeristy of Pretoria, South Africa): </i><b>"Aesthetic Consideration Around Implants"</b></li>
                        </ul>
                    </div>
                </div>

                <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F"}}>Sponzorske radionice:</p>
                        <p style={{fontStyle: 'italic'}}>ČETVRTAK 20.03.2025 godine:</p>
                        <ul style={{}}>
                            <li>(12:00-14:00) <i>Dr Milica Pejanović: </i> <b>"Nehiruška terapija parodontitisa i perimplantitisa"</b></li>
                        </ul>
                        {/* <p style={{fontStyle: 'italic'}}>PETAK 21.03.2025 godine:</p>
                        <ul style={{}}>
                            <li>(09:00-12:00) <i>Spec. dr Dejan Ivanovski: </i> <b>"Step – by – step procedure for efficient and predictable Endodontic treatment"</b></li>
                        </ul> */}
                        <p style={{fontStyle: 'italic'}}>SUBOTA 22.03.2025 godine:</p>
                        <ul style={{}}>
                            <li>(10:00-16:00) <i>Prof. dr Adrijan Kašaj: </i> <b>"Rekonstruktivna parodontalna hirurgija – kliničke inovacije"</b></li>
                        </ul>
                    </div>
                </div>

                {/* <div className="row" style={{paddingLeft:'8%', paddingTop:'15px'}}>
                    <div className="col-md-10 col-md-offset-1" >
                        <ul >
                            <li><i>Adrijan Kasaj sa tehničarem</i> <b></b></li>
                            <li><i>Milan Vučetić</i> <b></b></li>
                            <li><i>Marko Milićević</i> <b></b></li>
                        </ul>
                        <p style={{fontStyle: 'italic'}}>(Predavanje tehničari)</p>
                        <ul >
                            <li><i>Leon i Andrej Lazić</i> <b></b></li>
                        </ul>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default withRouter(CongressProgram)
import React from 'react'
import { withRouter } from 'react-router-dom'
import program from '../assets/img/program.png'
import kongres from '../assets/img/kongres.png'
import prijava from '../assets/img/prijava.png'
import kostic from '../assets/img/kostic.png'
import boljevic from '../assets/img/boljevic.png'
import delic from '../assets/img/delic.png'
import bulajic from '../assets/img/bulajic.jpg'
import { userPath } from '../constants/user.constants'


class Congress extends React.Component {

    showRegistrationPage = () => {
        let path = userPath.registration
        this.props.history.push(path)
    }

    showCongressDetailsPage = () => {
        let path = userPath.congressDetails
        this.props.history.push(path)
    }

    showCongressProgramDetailsPage = () => {
        let path = userPath.congressProgram
        this.props.history.push(path)
    }

    render() {
        return( 
            <div className="container">
                <div className="row" style={{paddingTop:'10px'}}>
                    <div className="col-sm-4 congress" onClick={this.showRegistrationPage}>
                        <div className="thumbnail" style={{padding:'10px'}}>
                            <img src={prijava} className="img-responsive" alt="" />
                            <div className="caption" style={{paddingTop: '5px'}}>
                                <h5 style={{color:"#EF662F"}}>
                                    Prijava
                                </h5>
                                    <p>Prijavu za Kongres možete izvršiti klikom na ikonu.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 congress" onClick={this.showCongressDetailsPage}>
                        <div className="thumbnail" style={{padding:'10px'}}>
                            <img src={kongres} className="img-responsive" alt="" />
                            <div className="caption" style={{paddingTop: '5px'}}>
                                <h5 style={{color:"#EF662F"}}>
                                    Kongres
                                </h5>
                                <p>Više informacija o Kongresu možete pogledati klikom na ikonu.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 congress" onClick={this.showCongressProgramDetailsPage}>
                        <div className="thumbnail" style={{padding:'10px'}}>
                            <img src={program} className="img-responsive" alt="" />
                            <div className="caption" style={{paddingTop: '5px'}}>
                                <h5 style={{color:"#EF662F"}}>
                                    Program
                                </h5>
                                <p>Više informacija o programu Kongresu možete pogledati klikom na ikonu.</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{paddingLeft:'8%'}}>
                    <div className="row" style={{paddingLeft:'5px', paddingRight:'5px'}}>
                        <div className="col-md-10 col-md-offset-1" >
                            <p style={{fontWeight:'bold', fontStyle: 'italic', color:"#EF662F", paddingBottom: '20px', paddingTop: '10px', textAlign:'center', fontSize:"16px"}}>Kongres stomatologa CG okupio "vrh" dentalne medicine regiona i svijeta</p>
                            <div style={{fontSize:'14px'}}>
                                <p style={{paddingBottom: '10px'}}>Stomatološka komora Crne Gore u Ministarstvu zdravlja ima iskrenog partnera u zajedničkom nastojanju da građanima Crne Gore obezbijede najbolju moguću stomatološku zaštitu. To je poručio generalni direktora Direktorata za zdravstvenu zaštitu, regulisane profesije i nadzor nad funkcionisanjem strukovnih regulatornih tijela (komora) u Ministarstvu zdravlja, dr Ognjen Delić na sinoćnjem otvaranju Petog kongresa stomatologa Crne Gore koji je okupio preko 350 stručnjaka iz zemlje, regiona i svijeta.</p>
                                <p style={{paddingBottom: '10px'}}>Novi model funkcionisanja stomatologije u Crnoj Gori, kako je objasnio, počivaće na tri ključna stuba razvoja.</p>
                                <p style={{paddingBottom: '10px'}}>“To su vraćanje stomatologije na nivo primarne zdravstvene zaštite, čime jačamo državnu stomatologiju i omogućavamo građanima lakši pristup uslugama; unapređenje saradnje sa privatnim ordinacijama koje imaju ugovore sa Fondom za zdravstveno osiguranje, jer vjerujemo da samo kroz partnerstvo i sinergiju možemo podići kvalitet zdravstvene zaštite i razvoj dentalnog turizma, gdje Crna Gora, zahvaljujući stručnom kadru i savremenoj opremi, ima izuzetan potencijal da postane prepoznatljiva destinacija na mapi evropskog dentalnog turizma”, kazao je Delić.</p>
                                <p style={{paddingBottom: '10px'}}>“U tom smislu, pozdravljam intenzivne i konkretne aktivnosti Stomatološke komore Crne Gore jer Ministarstvo zdravlja cijeni preduzimljivost kolega na bazi realnih pretpostavki. Istovremeno, vjerujem da u vama imamo saveznika i povodom, kako očekujem, opravdano rigoroznih mjera kontrole kako bismo osigurali najviše standarde kvaliteta u stomatološkoj zaštiti”, dodao je on.</p>
                                <img src={delic} className="img-responsive" alt="" />
                                <p style={{paddingBottom: '10px', fontSize:'12px'}}>Ognjen Delić - obraćanje možete pogledati na <a href='https://www.youtube.com/watch?v=7D9Dm00mtpc' target="_blank">linku</a></p>

                                <p style={{paddingBottom: '10px'}}>A, prilika za saradnju sa državnim ustanovama obavezuje Stomatološku komoru da istraje u zalaganju za pravičan i pravedan sistem vrednovanja rada crnogorskih stomatologa, ali i za unapređenje dostupnosti i efikasnosti stomatološke zdravstvene zaštite u našoj zemlji, kazala je V.D. predsjednice Stomatološke komore, Jelena Bulajić.</p>
                                <p style={{paddingBottom: '10px'}}>Govoreći o kvalitetu crnogorske stomatologije – Bulajić je naglasila da je na svjetskom nivou.</p>
                                <p style={{paddingBottom: '10px'}}>“Za razliku od drugih grana medicine, stomatologija u Crnoj Gori raspolaže svim resursima savremene dentalne medicine. Naši stručnjaci mogu ravnopravno stati uz kolege iz Evropske unije i Sjedinjenih Američkih Država, a i oprema sa kojom radimo dostiže najviše tehnološke standarde. Šta to znači za građane Crne Gore? To znači da apsolutno svi stomatološki zahvati mogu biti obavljeni u našoj zemlji, bez potrebe za odlaskom u inostranstvo”, kazala je ona.</p>
                                <p style={{paddingBottom: '10px'}}>Bulajić se, u tom smislu, osvrnula i na značaj ovog kongresa.</p>
                                <img src={bulajic} className="img-responsive" alt="" />
                                <p style={{paddingBottom: '10px' ,fontSize:'12px'}}>Jelena Bulajićić - obraćanje možete pogledati na <a href='https://www.youtube.com/watch?v=VAt_bskBeTs' target="_blank">linku</a></p>


                                <p style={{paddingBottom: '10px'}}>“Crna Gora je u granicama možda mala, ali svi vi koji ste večeras ovdje s nama - učinili ste nas centrom evropske stomatologije. Jednostavnim rječnikom - trenutno se nijedan stomatološki problem na tlu Evrope ne može brže i lakše riješiti nego ovdje u Tivtu”, izjavila je ona.</p>
                                <img src={boljevic} className="img-responsive" alt="" />
                                <p style={{paddingBottom: '10px' ,fontSize:'12px'}}>Tanja Boljević - obraćanje možete pogledati na <a href='https://www.youtube.com/watch?v=W4mlCqy411E' target="_blank">linku</a></p>
                                <p style={{paddingBottom: '10px'}}>Ovogodišnji kongres se održava pod sloganom “Savremeni pristupi i trendovi u stomatologiji”. Predsjednica Naučnog odbora, Tanja Boljević objasnila je da su programom nastojali da obuhvate najaktuelnije teme – od preventivne stomatologije i ranih intervencija kod djece, preko savremenih metoda u endodonciji, ortodonciji i parodontologiji, pa sve do naprednih hirurških tehnika i digitalnih protokola u protetici.</p>
                                <p style={{paddingBottom: '10px'}}>“Prvog dana kongresa, fokus će biti na značaju prevencije u dječijoj stomatologiji, ali i na endodontskim tretmanima koji predstavljaju izazov čak i za najiskusnije kliničare. Drugog dana, posebnu pažnju posvetićemo ortodonciji i parodontologiji, oblastima u kojima inovacije brzo napreduju i mijenjaju dosadašnje pristupe terapiji. Trećeg dana, bavićemo se oralnom hirurgijom i protetikom, gdje će poseban akcenat biti na digitalnim tehnologijama, implantologiji i rekonstruktivnim procedurama”, najavila je Boljević.</p>
                                <img src={kostic} className="img-responsive" alt="" />
                                <p style={{paddingBottom: '10px' ,fontSize:'12px'}}>Aleksandar Kostić - obraćanje možete pogledati na <a href='https://www.youtube.com/watch?v=LTVedXnbWio' target="_blank">linku</a></p>
                                <p style={{paddingBottom: '10px'}}>Predsjednik Organizacionog odbora, Aleksandar Kostić je posebno naglasio što kongresu, uz profesionalce sa višedecenijskim stažom, prisustvuju studenti i mladi ljekari.</p>
                                <p style={{paddingBottom: '10px'}}>“Podsjećam, kontinuirana edukacija nije samo profesionalna obaveza već i osnov budućeg napretka stomatologije. Zato je od izuzetnog značaja da mladi stomatolozi u svojoj zemlji imaju priliku da se sretnu s kolegama čija djela već citiraju u svojim radovima”, kazao je Kostić koji je kolegama poželio uspješan rad.</p>
                                <p style={{paddingBottom: '10px'}}>Otvaranju kongresa prisustvovao je potpredsjednik Vlade, Milun Zogović, predstavnici Ministarstva prosvjete, Fonda za zdravstveno osiguranje, Medicinskog fakulteta, Stomatološke poliklinike Kliničkog centra Crne Gore i Ljekarske komore Crne Gore.</p>
                                <p style={{paddingBottom: '10px'}}>Ovom skupu prisustvuju i predsjednici stomatoloških organizacija iz regiona, i to: Stomatološke komore Srbije, Hrvatske komore dentalne medicine, Komore doktora stomatologije Republike Srpske, Slovenačke stomatološke asocijacije i Stomatološke komore Sjeverne Makedonije.</p>
                                <p style={{paddingBottom: '10px'}}>Kongres traje do 23. marta.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="row" style={{paddingLeft:'8%'}}>
                    <div className="row" style={{paddingLeft:'5px', paddingRight:'5px'}}>
                        <div className="col-md-10 col-md-offset-1" >
                            <p style={{fontWeight:'bold', fontStyle: 'italic'}}>Rana kotizacija do 01.03.2025.godine:</p>
                            <ul style={{padding:'10px'}}>
                                <li>Za članove Komore - 150,00€</li>
                                <li>Učesnici koji nisu članovi Komore - 200,00€</li>
                            </ul>
                        
                            <p style={{fontWeight:'bold', fontStyle: 'italic'}}>Kasna kotizacija do 15.03.2025.godine:</p>
                            <ul style={{paddingTop:'10px'}}>
                                <li>Za članove Komore - 200,00€</li>
                                <li>Učesnici koji nisu članovi Komore - 250,00€</li>
                                <li>Kotizacija za tehničare - 100,00€</li>
                                <li>Kotizacija za studente je besplatna uz obaveznu potvrdu o studiranju</li>
                            </ul>
                            <p style={{fontWeight:'bold', fontStyle: 'italic'}}>*U okviru kotizacije uključeni su: ručak i kafe pauza u petak i subotu (21. i 22. mart 2025 godine).</p>

                            <div style={{paddingTop:'10px', paddingBottom:'15px'}}>
                                <p>
                                    Uplate za kotizaciju možete izvršiti na 
                                    žiro račun : 510-200340-32 CKB Banka 
                                    IBAN : ME25510000000021117619
                                </p>
                                <p>Gotovinsko plaćanje prilikom registracije nije moguće.</p>
                                <p>Pozivamo vas da i vi uzmete aktivno učešće prezentacijom naučnih radova i prikaza iz prakse i na taj način obogatite program edukacija.</p>
                            </div>


                            <p style={{fontWeight:'bold', fontStyle: 'italic', paddingBottom:'10px'}}>Rok za prijem rezimea: 01.03.2025</p>

                            <p style={{fontWeight:'bold', fontStyle: 'italic', paddingBottom:'10px', color:'darkred'}}>***Važno obavještenje: Za prijavu rezimea se koristi ista forma kao za prijavu učesnika kogresa (rad je neophodno uploadovati u poslednoj koloni "Rad")</p>

                            <p>Uz navedeni sažetak obavezno navesti način prezentacije:</p>
                            <ul style={{padding:'10px'}}>
                                <li>Oralna prezentacija (5 min) ili</li>
                                <li>Poster prezentacija u elektronskoj formi. Dostaviti dokaz o uplati kotizacije - Prihvaćeni radovi bez dokaza o uplati kotizacije neće biti objavljeni u zborniku radova.</li>
                                <li>Rezimei podlažu recenziji</li>
                                <li>Autori će biti obavješteni o odluci Naučnog odbora</li>
                            </ul>
                            <p style={{fontWeight:'bold', fontStyle: 'italic'}}>Učešće na Kogresu se boduje prema pravilniku Stomatološke Komore Crne Gore</p>
                            <ul style={{padding:'10px'}}>
                                <li>Pasivno učešće: 12 bodova</li>
                                <li>Aktivno učešće (prvi autor): 20 bodova</li>
                                <li>Drugi autor: 15 bodova</li>
                                <li>Predavač: 25 bodova</li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}

export default withRouter(Congress)